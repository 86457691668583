import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import leftDrawerSlice from '../features/common/leftDrawerSlice'
import UserSlice from './Slices/UserSlices'


const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  leftDrawer: leftDrawerSlice,
  user: UserSlice,
}

export default configureStore({
  reducer: combinedReducer
})