import { createSlice } from '@reduxjs/toolkit'

export const leftDrawerSlice = createSlice({
    name: 'leftDrawer',
    initialState: {
        isOpen: true,   // Left drawer state management for opening closing
    },
    reducers: {
        openLeftDrawer: (state, action) => {
            state.isOpen = true
        },
        closeLeftDrawer: (state, action) => {
            state.isOpen = false
        },
    }
})

export const { openLeftDrawer, closeLeftDrawer } = leftDrawerSlice.actions
export default leftDrawerSlice.reducer