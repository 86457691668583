import { baseUrl } from "./Core";
import axios from "axios";


export const LoginUser = async (formData) => {

    const result = await axios.post(`${baseUrl}/api/auth/login`, formData)
        .then(res => {
            return res?.data
        })
        .catch(error =>
            console.log(error)
        )
    return result
}

export const checkUser = async (token) => {

    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    try {
        const response = await axios.get(`${baseUrl}/api/me`, config)
            .then(res => {
                return res?.data
            })
            .catch(error =>
                console.log(error)
            )
        return response
    } catch (error) {
        console.error(error);
        throw error;
    }
};