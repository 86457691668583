import React, { lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { checkUser } from './api/Auth';
import { addUser } from './app/Slices/UserSlices';

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ViewContract = lazy(() => import('./pages/protected/ViewContract'))
const DepositSlip = lazy(() => import('./pages/protected/DepositSlip'))

const token = localStorage.getItem('token');

function App() {

  const dispatch = useDispatch();

  const CheckAuth = () => {
    if (token) {
      checkUser(token)
        .then((userData) => {
          dispatch(addUser(userData));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  useEffect(() => {
    CheckAuth()
  }, [])

  // const UserRole = useSelector(state => state?.user?.role);

  return (
    <>
      <Router>
        <Routes>
          {/* {!UserRole ? */}
            {/* <> */}
              <Route path="/" element={<Login />} />
            {/* </> */}
            {/* : */}
            <>
              <Route path="/dashboard/*" element={<Layout />} />
              <Route path="/deposit_slip/:id/:slip_for" element={<DepositSlip />} />
              <Route path="/contracts/view_contract" element={<ViewContract />} />
              <Route path="*" element={<Navigate to={"/dashboard/404"} />} />
            </>
          {/* } */}
        </Routes>
      </Router>
    </>
  )
}

export default App
